@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@charset "UTF-8";
body {
  width: 100%;
  position: relative;
  background-color: #000;
  padding: 0;
  margin: 0;
}

.mo-fire {
  width: 200px;
  height: auto;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 100px;
}

.mo-fire svg {
  width: 100%;
  height: auto;
  position: relative;
}

.flame {
  -webkit-animation-name: flamefly;
  animation-name: flamefly;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
}

.flame.one {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.flame3.two {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.flame-main {
  -webkit-animation-name: flameWobble;
  animation-name: flameWobble;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.flame-main.one {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.flame-main.two {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.flame-main.three {
  -webkit-animation-duration: 2.1s;
  animation-duration: 2.1s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.flame-main.four {
  -webkit-animation-duration: 3.2s;
  animation-duration: 3.2s;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.flame-main.five {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

@-webkit-keyframes flameWobble {
  50% {
    -webkit-transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
  }
}

@keyframes flameWobble {
  50% {
    -webkit-transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
  }
}

@-webkit-keyframes flamefly {
  0% {
    -webkit-transform: translate(0) rotate(180deg);
    transform: translate(0) rotate(180deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-20px, -100px) rotate(180deg);
    transform: translate(-20px, -100px) rotate(180deg);
    opacity: 0;
  }
}

@keyframes flamefly {
  0% {
    -webkit-transform: translate(0) rotate(180deg);
    transform: translate(0) rotate(180deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-20px, -100px) rotate(180deg);
    transform: translate(-20px, -100px) rotate(180deg);
    opacity: 0;
  }
}

#welcome-text {
  display: inline-block;
  text-align: center;
}

#welcome-image {
  box-shadow: 4px 6px 12px #888888;
  border-radius: 1%;
}

.dashImageButtons {
  height: 100%;
  width: 100%;
  -webkit-filter: drop-shadow(0px 1px 2px #979797);
  filter: drop-shadow(0px 1px 2px #979797);
  transition: 0.2s;
}

.dashImageButtons:hover {
  -webkit-filter: drop-shadow(2px 3px 4px #888888);
  filter: drop-shadow(2px 3px 4px #888888);
}

.dashButtons {
  background-color: Transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 50%;
  padding: 3px 4px 4px 3px;
}

.org-count-wrapper {
  background-color: #316ea9;
  color: #fff;
  padding: 30px 5px;
  text-align: center;
  border-radius: 10px;
  max-height: 130px;
  height: 120px;
  line-height: 15px;
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 1px 2px #979797);
  filter: drop-shadow(0px 1px 2px #979797);
}

.wave1 {
  background-image: url(/static/media/wave1.d05467f0.svg);
  background-repeat: no-repeat;
  background-position: 0% 110%;
}

.wave2 {
  background-image: url(/static/media/wave2.4cbcba8c.svg);
  background-repeat: no-repeat;
  background-position: top;
}

.wave3 {
  background-image: url(/static/media/wave3.84a61036.svg);
  background-repeat: no-repeat;
  background-position: 0% 110%;
}

.wave4 {
  background-image: url(/static/media/wave4.9f112f93.svg), url(/static/media/wave42.23a0c917.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top, 0% 110%;
  background-size: 100%, 60%;
}

.wave5 {
  background-image: url(/static/media/wave5.7dfed995.svg), url(/static/media/wave52.36471a4e.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top right, 0% 105%;
  background-size: 20%, 100%;
}

.anal-component-wrapper {
  background-color: #fff;
  border-radius: 20px;
  margin-left: 8%;
  padding: 10px;
  height: 330px;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
}

.dropdown-padding {
  padding-top: 9%;
}

.dashboard-more {
  display: block;
  background-color: #0b61a4 !important;
  color: #fff !important;
  padding: 10px 0px !important;
  width: 160px;
  border-radius: 10px !important;
  margin: 0 auto !important;
}

.component-wrapper {
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  width: 500px;
  height: 150px;
  margin-bottom: 10px;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
}

.row-spacing {
  margin-top: 8%;
}

.dropdown-padding {
  padding-top: 9%;
}

.dashboard-more {
  display: block;
  background-color: #0b61a4 !important;
  color: #fff !important;
  padding: 10px 0px !important;
  width: 160px;
  border-radius: 10px !important;
  margin: 0 auto !important;
}

.org-number-wrapper {
  font-size: 2.5em;
  font-weight: bolder;
  line-height: 50px;
}

.dash-secondary-heading {
  font-size: 17px;
  color: #12426f;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 5%;
  padding-left: 7%;
  letter-spacing: 1px;
}

.head-row {
  margin-left: 2% !important;
  margin-bottom: 1%;
}

.dash-heading {
  font-size: 1.6em;
  display: inherit;
  color: #065097;
  font-weight: 600;
  margin-bottom: 30px;
}

.fontawesome-dash {
  margin: 5px 15px 0 0;
  color: #065097;
}

div#dashboardAdmin {
  max-width: 1025px;
}

.head-row {
  margin-left: 2% !important;
  margin-bottom: 1%;
}

.fontawesome-dash {
  margin: 5px 15px 0 0;
  color: #065097;
}

.dashboard-register-button {
  background-color: #0b61a4 !important;
  color: #fff !important;
  border-radius: 8px !important;
  padding: 5px 10px !important;
  position: relative;
  left: 50%;
  bottom: 30%;
  font-size: 14px;
}

.dashboard-psap-button {
  background-color: #0b61a4 !important;
  color: #fff !important;
  margin-right: 13px !important;
  border-radius: 8px !important;
  padding: 13px 43px !important;
}

.dash-view-button {
  margin-right: 16px !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  color: #0b61a4 !important;
  background-color: transparent !important;
  border: none;
  position: relative;
  left: 70%;
  bottom: 30%;
  font-weight: 500;
  font-size: 14px;
}

#dashRowAdmin {
  margin-left: 3%;
  margin-bottom: 2%;
}

#dashRowPsap {
  margin-bottom: 2%;
  margin-top: 6%;
  background-color: #fff;
  border-radius: 20px;
  margin-left: 2%;
  padding: 4% 0%;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.dash-dropdown button {
  border-radius: 4px !important;
  padding: 7px 2px !important;
  color: #0b61a4 !important;
  background-color: transparent !important;
  border: 1px solid #0b61a4 !important;
}

button#dropdown-year {
  background-color: transparent !important;
  min-width: 110px;
  text-align: left;
}

button#dropdown-region {
  background-color: transparent !important;
  min-width: 110px;
  text-align: left;
}

.no-right-pad {
  padding-right: 0px !important;
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 540px) {
  .col-sm-2dot4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2dot4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 960px) {
  .col-lg-2dot4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2dot4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.tableNest {
  background-color: #f3f3f3;
  z-index: -1;
  border-radius: 1%;
  padding: 1% 1%;
  border: 1px solid #e9e9e9;
}

#usersHeading {
  padding-top: 2%;
}

.dropzone {
  text-align: center;
  padding: 25px;
  border: 3px dashed #ababab;
  border-radius: 5px;
  background-color: #fafafa;
  outline: none;
  color: #bdbdbd;
  margin: 50px 25px;
  height: 250px;
}

#change-profile-picture-text {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #045097;
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
  font-size: 14px;
}

.react-avatar {
  display: block !important;
  margin: auto !important;
  cursor: pointer;
}

.ProfileSettingsForm {
  margin-top: -20px;
}

.profile-settings-row {
  margin-top: 50px;
  border-radius: 15px;
  box-shadow: 0px 3px 10px #058abc3d;
  background: #ffffff 0% 0% no-repeat padding-box;
}

div.row.profile-settings-row {
  padding: 15px;
}

.ProfileSettingsForm input.form-control {
  border: none;
  padding: 0px;
}

.ProfileSettingsForm input.form-control:focus {
  border-bottom: 1px solid #058abc3d;
  border-radius: 0px;
  box-shadow: none;
}

.ProfileSettingsForm .custom-form-label {
  color: #585a5a;
}

.ProfileSettingsForm input[name='username'] {
  font-size: 26px;
  font-weight: bold;
}

.input-pen-svg {
  color: #058abc3d;
  z-index: 99909;
  margin-left: -20px;
  margin-top: 15px;
}

.tableNest {
  background-color: #f3f3f3;
  z-index: -1;
  border-radius: 1%;
  padding: 1% 1%;
  border: 1px solid #e9e9e9;
}

.loader {
  color: #009fff;
  font-size: 20px;
  margin: 100px auto;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

#cisMapModalContainer {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(131, 131, 131, 0.247);
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
}

#CisMapModal {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: auto;
}

#OfficialMap {
  margin: auto;
  width: 100%;
  height: 500px;
}

#cisMapContainer {
  position: relative;
  overflow: hidden;
}

.first-responders-modal {
  color: #4b4646;
  margin-top: 23px;
  margin: 20px;
}

#submit-button-first-responders {
  margin-top: 30px;
  margin-left: 35%;
  width: 30%;
  background-color: #3f4d77;
}

#sidemenu {
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  max-width: 220px;
  line-height: 1.2;
  background-color: #fff;
  letter-spacing: normal;
  text-align: left;
}

#sidemenu-hidden {
  display: none;
}

.sidemenu-ul {
  margin-top: 25%;
  width: 100%;
  display: block;
  float: right;
  list-style: none;
  -webkit-padding-start: 19px;
  padding-inline-start: 19px;
}

#sidemenu-ul-register {
  list-style: none;
  width: 100%;
  height: 0vh;
  overflow: hidden;
  transition: height 0.3s ease-out;
  padding-left: 0%;
  border-bottom-left-radius: 15px;
}

#sidemenu-ul-show {
  list-style: none;
  width: 100%;
  height: 0vh;
  overflow: hidden;
  transition: height 0.3s ease-out;
  padding-left: 0%;
  border-bottom-left-radius: 15px;
}

#sidemenu-ul-incidents {
  list-style: none;
  width: 100%;
  height: 0vh;
  overflow: hidden;
  transition: height 0.3s ease-out;
  background-color: #065197;
  padding-left: 0%;
  border-top: #2573b6 solid 1.05px;
}

.sidemenu-li {
  cursor: pointer;
  background-color: transparent;
  font-size: 0.75em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  padding: 5% 2% 4% 4%;
  position: relative;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #065097;
}

.sidemenu-li:hover {
  background-color: #d3e8fc !important;
  color: #065097 !important;
}

.sidemenu-li:active {
  color: #fff;
}

#borderRight {
  background: #fed;
  position: relative;
}

.activeMI > li {
  background-color: #356ea5 !important;
  color: #fff !important;
}

.sidemenu-li-sub {
  cursor: pointer;
  background-color: transparent;
  font-size: 0.75em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  width: 100%;
  padding: 4% 3% 4% 3%;
  color: #065097;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.sidemenu-li-sub:hover {
  background-color: #d3e8fc !important;
  color: #065097 !important;
}

#sidemenu-col {
  padding: 0%;
  background-color: #e1ebf3;
}

.fontawesome-sidemenu {
  margin-right: 10%;
  margin-left: 2%;
}

.fontawesome-sidemenu-sub {
  margin-right: 10%;
  margin-left: 10%;
}

#header-container {
  height: 100%;
}

.header-options {
  margin: 2% 0%;
}

.header-options.col-1 {
  padding-left: 0%;
  padding-right: 0%;
}

.header-options svg,
.header-options a {
  color: #045097;
}

#app-row-header {
  position: relative;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
  z-index: 1;
}

#header-logo {
  margin-top: 29px;
  margin-left: 15px;
  float: left;
  max-width: 12%;
  height: auto;
}

#header-row {
  background-color: #ffffff;
}

.fontawesome-header {
  -webkit-filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.3));
}

#user-dropdown-toggle {
  background-color: white !important;
  color: #045097;
  border: 1px solid #f6f6f6;
  border-radius: 25px;
  box-shadow: none;
}

#user-dropdown-photo {
  border-radius: 50%;
}

@media (max-width: 1200px) {
  #user-dropdown-username {
    display: none;
  }
}

@media (min-width: 1200px) {
  #user-dropdown-username {
    display: inline;
  }
}

#user-dropdown-username {
  padding: 0px 10px;
  text-transform: none;
  font-weight: 500;
}

.user-dropdown-item > a {
  color: black;
}

.user-dropdown-item > a > img {
  padding-right: 15px;
}

.user-dropdown-li-img {
  display: inline-block;
  width: 35px;
}

a.user-dropdown-item:not(:last-child) {
  border-bottom: 1px #f6f6f6 solid;
}

a.user-dropdown-item:active {
  background-color: #f8f9fa !important;
}

a.user-dropdown-item:hover {
  background-color: white !important;
}

a.user-dropdown-item > a:hover {
  color: black !important;
  text-decoration: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cis-grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.3fr 0.5fr 0.5fr;
    grid-template-columns: 1.3fr 0.5fr 0.5fr;
    -ms-grid-rows: 0.5fr 1.5fr 1fr;
    grid-template-rows: 0.5fr 1.5fr 1fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas: 'cis-Incident-Info cis-Weather' 'cis-Map-CIS cis-Map-CIS' 'cis-DSS-headers cis-DSS-headers' 'cis-DSS-CIS cis-DSS-CIS';
  }
  .cis-DSS-headers {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-headers;
    display: flexbox;
  }
  .cis-DSS-headers .dss-fire-fighters {
    display: inline-flexbox;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .cis-DSS-headers .dss-fire-trucks {
    display: inline-flexbox;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .cis-DSS-headers .dss-hikers {
    display: inline-flexbox;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .cis-DSS-headers .dss-volunteers {
    display: inline-flexbox;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .cis-DSS-headers .dss-acres {
    display: none;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-CIS;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    margin-top: 1%;
  }
  .cis-DSS-CIS .dss-container {
    display: flexbox;
  }
  .cis-DSS-CIS .fire-fighters {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: -40%;
  }
  .cis-DSS-CIS .fire-trucks {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .hikers {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .volunteers {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .acres {
    width: 100%;
    height: 100%;
    border-color: #0b61a4;
    border-style: solid;
    border-radius: 15px;
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .cis-Incident-Info {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: cis-Incident-Info;
    height: 100%;
    width: 100%;
  }
  .cis-Incident-Info ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  .cis-Incident-Info ul li {
    display: inline-block;
    font-size: 13px;
    margin: 5px;
    padding: 0.25em 0.25em;
  }
  .cis-Weather {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: cis-Weather;
    width: 100%;
    height: 100%;
  }
  .cis-Weather .weather-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-align: right;
    margin-top: 39%;
  }
  .cis-Weather .weather-description {
    float: right;
    text-align: right;
    font-size: 25;
    color: #065097;
    font-weight: 650;
    margin-top: 8%;
  }
  .cis-Weather .cloud-image {
    position: relative;
    right: -40%;
    margin-top: 10%;
  }
  .cis-Map-CIS {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-Map-CIS;
    width: 97%;
    height: 90%;
    margin-left: 10px;
  }
  .cis-Map-CIS .arrived-button {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 100;
    top: 60%;
    right: 35%;
  }
  .backdrop-cis {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 52%;
    left: 0;
    background: rgba(211, 201, 201, 0.733);
    z-index: 95;
  }
  .button-arrived-yellow {
    color: black;
    background-color: #f1e10c;
    border: none;
    height: 50px;
    width: 200px;
    font-size: 16px;
    place-items: center;
  }
  .h5-psap-style {
    font-size: 14px;
    color: #065097;
    margin-right: 29px;
  }
  .h4-psap-style {
    font-size: 16px;
    color: #065097;
    margin-left: 2%;
    font-weight: 600;
  }
  .h3-psap-style {
    color: #065097;
    margin-left: 2%;
    font-size: 25px;
    margin-top: 2%;
  }
  .button-request-troops {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 1;
    top: 50%;
    right: 50%;
  }
  #request-troops-button {
    background-color: #d94f4f;
    color: white;
  }
  .incident-id {
    color: #2474bf;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .cis-grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.7fr 0.3fr;
    grid-template-columns: 1.7fr 0.3fr;
    -ms-grid-rows: 0.5fr 1.5fr;
    grid-template-rows: 0.5fr 1.5fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas: 'cis-Incident-Info cis-Weather' 'cis-Map-CIS cis-Map-CIS' 'cis-DSS-headers cis-DSS-headers' 'cis-DSS-CIS cis-DSS-CIS';
  }
  .cis-DSS-headers {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-headers;
    display: flex;
  }
  .cis-DSS-headers .dss-fire-fighters {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-fire-trucks {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-hikers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-volunteers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-acres {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-CIS;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    margin-top: 1%;
  }
  .cis-DSS-CIS .dss-container {
    display: flex;
  }
  .cis-DSS-CIS .fire-fighters {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .fire-trucks {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .hikers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .volunteers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .acres {
    width: 100%;
    height: 100%;
    border-color: #0b61a4;
    border-style: solid;
    border-radius: 15px;
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .cis-Incident-Info {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: cis-Incident-Info;
    height: 100%;
    width: 100%;
  }
  .cis-Incident-Info ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  .cis-Incident-Info ul li {
    display: inline-block;
    font-size: 12px;
    margin: 5px;
    padding: 0.25em 0.25em;
  }
  .cis-Weather {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: cis-Weather;
    width: 100%;
    height: 100%;
  }
  .cis-Weather .weather-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 10px;
    text-align: right;
    margin-top: 5%;
    right: 10%;
    position: relative;
    font-size: 12px;
  }
  .cis-Weather .weather-description {
    text-align: right;
    font-size: 15px;
    color: #065097;
    font-weight: 600;
    margin-top: 8%;
    right: 10%;
    position: relative;
  }
  .cis-Weather .cloud-image {
    position: relative;
    right: 10%;
  }
  .cis-Map-CIS {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-Map-CIS;
    width: 90%;
    height: 90%;
    margin-left: 10px;
  }
  .cis-Map-CIS .arrived-button {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 100;
    top: 40%;
    right: 45%;
  }
  .backdrop-cis {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 35%;
    left: 0;
    background: rgba(211, 201, 201, 0.733);
    z-index: 95;
  }
  .button-arrived-yellow {
    z-index: 1;
    color: black;
    background-color: #f1e10c;
    border: none;
    height: 45px;
    width: 200px;
    position: relative;
    font-size: 17px;
    font-weight: 400;
    top: 500%;
    left: 40%;
    border: 1px solid black;
    border-radius: 5%;
  }
  .h5-psap-style {
    font-size: 13px;
    color: #065097;
    margin-right: 29px;
  }
  .h4-psap-style {
    font-size: 14px;
    color: #065097;
    margin-left: 2%;
    font-weight: 600;
  }
  .h3-psap-style {
    color: #065097;
    margin-left: 2%;
    font-size: 25px;
  }
  .button-request-troops {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 1;
    top: 50%;
    right: 50%;
  }
  #request-troops-button {
    background-color: #d94f4f;
    color: white;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cis-grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.2fr 0.5fr;
    grid-template-columns: 1.2fr 0.5fr;
    -ms-grid-rows: 0.5fr 1.5fr;
    grid-template-rows: 0.5fr 1.5fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas: 'cis-Incident-Info cis-Weather' 'cis-Map-CIS cis-Map-CIS' 'cis-DSS-headers cis-DSS-headers' 'cis-DSS-CIS cis-DSS-CIS';
  }
  .cis-DSS-headers {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-headers;
    display: flex;
  }
  .cis-DSS-headers .dss-fire-fighters {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-fire-trucks {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-hikers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-volunteers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-acres {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-CIS;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    margin-top: 1%;
  }
  .cis-DSS-CIS .dss-container {
    display: flex;
  }
  .cis-DSS-CIS .fire-fighters {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .fire-trucks {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .hikers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .volunteers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .acres {
    width: 100%;
    height: 100%;
    border-color: #0b61a4;
    border-style: solid;
    border-radius: 15px;
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .cis-Incident-Info {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: cis-Incident-Info;
    height: 100%;
    width: 100%;
  }
  .cis-Incident-Info ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  .cis-Incident-Info ul li {
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    padding: 0.25em 0.25em;
  }
  .cis-Weather {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: cis-Weather;
    width: 93%;
    height: 100%;
  }
  .cis-Weather .weather-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-align: right;
    margin-top: 10%;
  }
  .cis-Weather .weather-description {
    right: 22%;
    text-align: right;
    color: #065097;
    font-weight: 600;
  }
  .cis-Weather .cloud-image {
    position: relative;
    margin-right: 5%;
  }
  .cis-Map-CIS {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-Map-CIS;
    width: 100%;
    height: 90%;
    margin-left: 10px;
  }
  .cis-Map-CIS .arrived-button {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 100;
    top: 40%;
    right: 50%;
  }
  .backdrop-cis {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(197, 187, 187, 0.733);
    z-index: 95;
    left: 20%;
    top: 25%;
  }
  .button-arrived-yellow {
    z-index: 1;
    color: black;
    background-color: #f1e10c;
    border: none;
    height: 50px;
    width: 200px;
    position: relative;
    font-size: 17px;
    font-weight: 500;
    top: 400%;
    left: 50%;
    border: 1px solid black;
  }
  .h5-psap-style {
    font-size: 12px;
    color: #065097;
    margin-right: 29px;
  }
  .h4-psap-style {
    font-size: 14px;
    color: #065097;
    margin-left: 2%;
    font-weight: 600;
  }
  .h3-psap-style {
    color: #065097;
    margin-left: 2%;
    font-size: 25px;
  }
  .button-request-troops {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 1;
    top: 50%;
    right: 50%;
  }
  #request-troops-button {
    background-color: #d94f4f;
    color: white;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cis-grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.1fr 0.5fr;
    grid-template-columns: 1.1fr 0.5fr;
    -ms-grid-rows: 0.5fr 1.5fr;
    grid-template-rows: 0.5fr 1.5fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas: 'cis-Incident-Info cis-Weather' 'cis-Map-CIS cis-Map-CIS' 'cis-DSS-headers cis-DSS-headers' 'cis-DSS-CIS cis-DSS-CIS';
  }
  .cis-DSS-headers {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-headers;
    display: flex;
  }
  .cis-DSS-headers .dss-fire-fighters {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-fire-trucks {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-hikers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-volunteers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-acres {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-CIS;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    margin-top: 1%;
  }
  .cis-DSS-CIS .dss-container {
    display: flex;
  }
  .cis-DSS-CIS .fire-fighters {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .fire-trucks {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .hikers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .volunteers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .acres {
    width: 100%;
    height: 100%;
    border-color: #0b61a4;
    border-style: solid;
    border-radius: 15px;
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .cis-Incident-Info {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: cis-Incident-Info;
    height: 100%;
    width: 100%;
  }
  .cis-Incident-Info ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  .cis-Incident-Info ul li {
    display: inline-block;
    font-size: 15px;
    margin: 5px;
    padding: 0.25em 0.25em;
  }
  .cis-Weather {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: cis-Weather;
    width: 100%;
    height: 100%;
  }
  .cis-Weather .weather-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: right;
    margin-top: 5%;
  }
  .cis-Weather .weather-description {
    float: right;
    text-align: right;
    font-size: 25;
    color: #065097;
    font-weight: 650;
    margin-top: 8%;
  }
  .cis-Weather .cloud-image {
    position: relative;
    left: 24%;
  }
  .cis-Map-CIS {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-Map-CIS;
    width: 100%;
    height: 90%;
    margin-top: -2%;
  }
  .cis-Map-CIS .arrived-button {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 100;
    top: 40%;
    right: 50%;
  }
  .button-arrived-yellow {
    z-index: 1;
    color: black;
    background-color: #f1e10c;
    border: none;
    height: 65px;
    width: 200px;
    position: relative;
    font-size: 17px;
    font-weight: 500;
    top: 300%;
    left: 50%;
  }
  .h5-psap-style {
    font-size: 14px;
    color: #065097;
    margin-right: 29px;
  }
  .h4-psap-style {
    font-size: 14px;
    color: #065097;
    margin-left: 2%;
    font-weight: 600;
  }
  .h3-psap-style {
    color: #065097;
    margin-left: 2%;
    font-size: 25px;
  }
  .button-request-troops {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 1;
    top: 50%;
    right: 50%;
  }
  #request-troops-button {
    background-color: #d94f4f;
    color: white;
  }
  .incident-id {
    color: #2474bf;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .cis-grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.5fr 0.5fr;
    grid-template-columns: 1.5fr 0.5fr;
    -ms-grid-rows: 0.5fr 1.5fr;
    grid-template-rows: 0.5fr 1.5fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas: 'cis-Incident-Info cis-Weather' 'cis-Map-CIS cis-Map-CIS' 'cis-DSS-headers cis-DSS-headers' 'cis-DSS-CIS cis-DSS-CIS';
  }
  .cis-DSS-headers {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-headers;
    display: flex;
  }
  .cis-DSS-headers .dss-fire-fighters {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-fire-trucks {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-hikers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-volunteers {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-headers .dss-acres {
    display: inline-flex;
    position: relative;
    left: 5%;
    color: #0b61a4;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-DSS-CIS;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    margin-top: 1%;
  }
  .cis-DSS-CIS .dss-container {
    display: flex;
  }
  .cis-DSS-CIS .fire-fighters {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .fire-trucks {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .hikers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .volunteers {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  .cis-DSS-CIS .acres {
    width: 100%;
    height: 100%;
    border-color: #0b61a4;
    border-style: solid;
    border-radius: 15px;
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .cis-Incident-Info {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: cis-Incident-Info;
    height: 100%;
    width: 100%;
  }
  .cis-Incident-Info ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  .cis-Incident-Info ul li {
    display: inline-block;
    font-size: 13px;
    margin: 5px;
    padding: 0.25em 0.25em;
  }
  .cis-Weather {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: cis-Weather;
    width: 100%;
    height: 100%;
  }
  .cis-Weather .weather-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    text-align: right;
    margin-top: 12%;
  }
  .cis-Weather .weather-description {
    float: right;
    text-align: right;
    font-size: 16px;
    color: #065097;
    font-weight: 650;
    margin-top: 9%;
  }
  .cis-Weather .cloud-image {
    position: relative;
    left: 60px;
    top: 15%;
  }
  .cis-Map-CIS {
    margin-top: -1%;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cis-Map-CIS;
    width: 100%;
    height: 90%;
  }
  .cis-Map-CIS .arrived-button {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 100;
    top: 60%;
    right: 45%;
  }
  .button-arrived-yellow {
    z-index: 1;
    background-color: #f1e10c;
    border: none;
    height: 60px;
    width: 200px;
    position: relative;
    font-size: 17px;
    font-weight: 500;
    top: -200%;
    left: 10%;
  }
  .h5-psap-style {
    font-size: 14px;
    color: #065097;
    margin-right: 29px;
    font-weight: 500;
  }
  .h4-psap-style {
    font-size: 14px;
    color: #065097;
    margin-left: 2%;
    font-weight: 600;
  }
  .h3-psap-style {
    color: #065097;
    margin-left: 2%;
    font-size: 25px;
  }
  .button-request-troops {
    display: -ms-grid;
    display: grid;
    position: absolute;
    place-items: center;
    z-index: 1;
    top: 50%;
    right: 50%;
  }
  #request-troops-button {
    background-color: #d94f4f;
    color: white;
  }
  .incident-id {
    color: #2474bf;
  }
  #active-button-psap {
    background-color: #9b4b4b;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 0.1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    grid-template-areas: 'Header' 'Map' 'Notifications' 'Orgs' 'Members' 'Incidents';
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: Header;
    margin-top: 3%;
    margin-left: 6%;
  }
  .Orgs {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Orgs;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Members {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: Members;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 3%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Incidents {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
    grid-area: Incidents;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-top: -3%;
    margin-left: 6%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 90%;
    margin-left: 6%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    opacity: 0.8;
    padding: 0;
    margin-top: 12px;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95%;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 4%;
    font-size: 14px;
    list-style-type: none;
    cursor: pointer;
  }
  .incident-number-notification {
    color: #2474bf;
    font-weight: 600;
  }
  .requestedEms-number-notification-false {
    color: #d52236;
    font-weight: 600;
    margin: 25px;
  }
  .requestedEms-number-notification-true {
    color: #18851b;
    font-weight: 600;
    margin: 30px;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
  }
  #notification-deny-text {
    color: #d52236;
    margin: 30px;
    font-weight: 600;
  }
  .more-updates {
    color: #2474bf;
    margin-left: 350px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 67%;
    font-size: 90%;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: 'Roboto';
    font-size: 13px;
    padding-left: 7%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 0.1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 10px 1px;
    gap: 10px 1px;
    grid-template-areas: 'Header' 'Map' 'Notifications' 'Orgs' 'Members' 'Incidents';
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: Header;
    margin-top: 5%;
    margin-left: 14%;
  }
  .Orgs {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Orgs;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    width: 75%;
    margin-left: 14%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Members {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: Members;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 75%;
    margin-left: 14%;
  }
  .Incidents {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
    grid-area: Incidents;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 75%;
    margin-left: 14%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 75%;
    margin-left: 14%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 75%;
    margin-left: 14%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    opacity: 0.8;
    padding: 0;
    margin-top: 15px;
    margin-left: 10%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95%;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 14px;
    list-style-type: none;
    cursor: pointer;
  }
  .incident-number-notification {
    color: #2474bf;
    font-weight: 550;
  }
  .requestedEms-number-notification-false {
    color: #d52236;
    font-weight: 500;
    margin: 25px;
  }
  .requestedEms-number-notification-true {
    color: #18851b;
    font-weight: 500;
    margin: 30px;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
  }
  #notification-deny-text {
    color: #d52236;
    margin: 5%;
    font-weight: 500;
  }
  .more-updates {
    color: #2474bf;
    margin-left: 350px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 58%;
    font-size: 90%;
    margin-top: -3%;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: 'Roboto';
    font-size: 14px;
    padding-left: 7%;
  }
  .dash-secondary-heading {
    font-size: 18px;
    color: #12426f;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1%;
    padding-left: 7%;
    letter-spacing: 1px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 0.1fr 1.9fr 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 1.9fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 15px 10px;
    gap: 15px 10px;
    grid-template-areas: 'Header Header' 'Map Map' 'Notifications Orgs' 'Notifications Members' 'Notifications Incidents';
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: Header;
  }
  .Orgs {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: Orgs;
    background-color: white;
    border-style: hidden;
    border-radius: 25px;
    padding: 5%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Members {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    grid-area: Members;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .Incidents {
    -ms-grid-row: 5;
    -ms-grid-column: 2;
    grid-area: Incidents;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    box-shadow: 5px 5px 10px #405f051a;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 66%;
    width: 100%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    opacity: 0.8;
    padding: 0;
    margin-top: 15px;
    margin-left: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95%;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 7%;
    font-size: 12px;
    list-style-type: none;
  }
  .incident-number-notification {
    color: #2474bf;
    font-weight: 550;
  }
  .requestedEms-number-notification-false {
    color: #d52236;
    font-weight: 500;
    margin: 25px;
  }
  .requestedEms-number-notification-true {
    color: #18851b;
    font-weight: 500;
    margin: 25px;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
  }
  #notification-deny-text {
    color: #d52236;
    margin: 7%;
    font-weight: 500;
  }
  .more-updates {
    color: #2474bf;
    margin-left: 350px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 66%;
    font-size: 81%;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: 'Roboto';
    font-size: 13px;
    padding-left: 7%;
  }
  .dashboard-register-button {
    background-color: #0b61a4 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 30%;
    font-size: 11px;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #0b61a4 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 30%;
    font-weight: 500;
    font-size: 11px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 0.1fr 1fr 0.6fr 0.4fr;
    grid-template-rows: 0.1fr 1fr 0.6fr 0.4fr;
    grid-gap: 9px 9px;
    gap: 9px 9px;
    grid-template-areas: 'Header Header Header' 'Map Map Map' 'Notifications Notifications Notifications' 'Orgs Members Incidents';
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Header;
    margin-top: -1%;
    margin-left: 6%;
  }
  .Orgs {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Orgs;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Members {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    grid-area: Members;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Incidents {
    -ms-grid-row: 4;
    -ms-grid-column: 3;
    grid-area: Incidents;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 10px;
    height: 100%;
    width: 90%;
    margin-left: 6%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    opacity: 0.8;
    padding: 0;
    margin-top: 15px;
    margin-left: 0;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95%;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 15px;
    list-style-type: none;
  }
  .incident-number-notification {
    color: #2474bf;
    font-weight: 550;
  }
  .requestedEms-number-notification-false {
    color: #d52236;
    font-weight: 500;
    margin: 25px;
  }
  .requestedEms-number-notification-true {
    color: #18851b;
    font-weight: 500;
    margin: 30px;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin: 30px;
  }
  #notification-deny-text {
    color: #d52236;
    margin: 30px;
    font-weight: 500;
  }
  .more-updates {
    color: #2474bf;
    margin-left: 350px;
    font-weight: 500;
    margin-left: 65%;
    font-size: 88%;
    cursor: pointer;
  }
  .dashboard-register-button {
    background-color: #0b61a4 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 30%;
    font-size: 11px;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #0b61a4 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 30%;
    font-weight: 500;
    font-size: 11px;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 12px;
    padding-left: 7%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 0.1fr 1.7fr 0.7fr;
    grid-template-rows: 0.1fr 1.7fr 0.7fr;
    grid-gap: 20px 20px;
    gap: 20px 20px;
    grid-template-areas: 'Header Header Header Header' 'Notifications Map Map Tweets' 'Incidents Orgs Members Tweets';
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Header;
    margin: 0;
    padding: 0;
    margin-bottom: -5%;
    margin-top: -1%;
  }
  .Orgs {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: Orgs;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Tweets {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
    grid-area: Tweets;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 90%;
  }
  .Members {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: Members;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Incidents {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Incidents;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: Map;
    background-color: rgba(255, 255, 255, 0.863);
    border-style: hidden;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Notifications {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 100%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    opacity: 0.8;
    padding: 0;
    margin-top: 15px;
    margin-left: 6.5%;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 275px;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 12px;
    list-style-type: none;
    cursor: pointer;
  }
  .incident-number-notification {
    color: #2474bf;
    font-weight: 550;
  }
  .requestedEms-number-notification-false {
    color: #d52236;
    font-weight: 500;
    margin: 5%;
  }
  .requestedEms-number-notification-true {
    color: #18851b;
    font-weight: 500;
    margin: 5%;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
  }
  #notification-deny-text {
    color: #d52236;
    margin: 5%;
    font-weight: 500;
  }
  .more-updates {
    color: #2474bf;
    margin-left: 350px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 64%;
    font-size: 83%;
    cursor: pointer;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: 'Roboto';
    font-size: 13px;
    padding-left: 7%;
  }
  .dashboard-register-button {
    background-color: #0b61a4 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 30%;
    font-size: 11px;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #0b61a4 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 30%;
    font-weight: 500;
    font-size: 11px;
  }
  #active-buttton-psap {
    background-color: #0b61a4;
  }
}

/* .SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
} */
@media (max-width: 767px) {
  .side-drawer {
    height: 100%;
    background: rgba(245, 241, 241, 0.925);
    position: fixed;
    box-shadow: 2px 0px 5px rgba(245, 241, 241, 0.925);
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    max-width: 50%;
    z-index: 200;
  }
  .side-drawer #sidemenu {
    display: inline-block;
  }
  .side-drawer #sidemenu .sidemenu-ul {
    display: inline-block;
  }
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 251, 251, 0.733);
    z-index: 100;
  }
  .toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
    height: 20%;
    width: 20%;
    position: relative;
    bottom: 4px;
  }
  .toggle-button:focus {
    outline: none;
  }
  .toggle-button__line {
    width: 40%;
    height: 2px;
    background: #ce6464;
    margin-top: 3px;
    margin-left: 18%;
  }
  .sidemenu-ul {
    display: none;
  }
  #sidemenu-col {
    display: none;
  }
  #app-body {
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #header-row {
    width: 100%;
  }
  #header-logo {
    position: relative;
    max-width: 50%;
    max-height: 50%;
    top: 19px;
    left: 18%;
    float: none;
    margin: 0;
  }
}

@media (min-width: 767px) {
  .toggle-button {
    display: none;
  }
  .toggle-button__line {
    display: none;
  }
  .side-drawer {
    display: none;
  }
}

#mainchat-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: 5px 10px;
  background-color: beige;
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 1px darkblue;
}

.chat-modal {
  width: 90vw;
  max-width: 90vw;
  max-height: 100px;
}

.chatApp {
  display: -ms-grid;
  display: grid;
  height: 100%;
  -ms-grid-columns: (1fr) [6];
  grid-template-columns: repeat(6, 1fr);
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-areas: 'r m m m m m' 'r m m m m m' 'r m m m m m' 'r m m m m m' 'r m m m m m' 'r f f f f f';
}

.new-room-form {
  grid-area: n;
}

.rooms-list {
  -ms-grid-row: 1;
  -ms-grid-row-span: 6;
  -ms-grid-column: 1;
  grid-area: r;
}

.message-list {
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  -ms-grid-column: 2;
  -ms-grid-column-span: 5;
  grid-area: m;
}

.send-message-form {
  -ms-grid-row: 6;
  -ms-grid-column: 2;
  -ms-grid-column-span: 5;
  grid-area: f;
}

.rooms-list {
  box-sizing: border-box;
  padding: 10px;
  background-color: #5ea3d0;
  overflow: scroll;
  overflow: hidden;
}

.rooms-list ul {
  list-style-type: none;
  padding: 0;
  overflow: scroll;
  overflow: hidden;
}

.rooms-list li {
  margin: 10px 0;
}

.rooms-list h3 {
  margin: 5px 0;
  color: white;
}

.rooms-list .room a {
  color: #b0c7d6;
  font-weight: 600;
  text-decoration: none;
}

.rooms-list .room.active a {
  color: white;
}

.new-room-form {
  padding: 0 5px;
  background: white;
  color: #3e5869;
}

.new-room-form {
  padding: 0 5px;
  background: white;
  color: #3e5869;
}

.new-room-form form {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-room-form input {
  width: 135px;
  background: white;
}

.new-room-form button {
  background: white;
  color: #3e5869;
  border: 0;
}

.new-room-form input::-webkit-input-placeholder {
  color: #3e5869;
  font-weight: 200;
}

.new-room-form input:-ms-input-placeholder {
  color: #3e5869;
  font-weight: 200;
}

.new-room-form input::placeholder {
  color: #3e5869;
  font-weight: 200;
}

.new-room-form input:focus {
  outline-width: 0;
}

.new-room-form input {
  border: 0;
}

.new-room-form button {
  border: 0;
}

.message {
  margin: 15px 0;
}

.message .message-username {
  font-size: 11px;
  color: #3e5869;
  opacity: 0.9;
  margin-bottom: 6px;
}

.message .message-text {
  background: #5ea3d0;
  color: white;
  display: inline;
  padding: 4px 8px;
  border-radius: 8px;
}

.message-list {
  box-sizing: border-box;
  padding-left: 6px;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
}

.message-list .join-room {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 34px;
  font-weight: 300;
}

.send-message-form {
  background: #f5f5f5;
  display: flex;
}

.send-message-form input {
  width: 100%;
  padding: 15px 10px;
  margin: 0;
  border-style: none;
  background: #f5f5f5;
  font-weight: 200;
}

.send-message-form input:focus {
  outline-width: 0;
}

.send-message-form input::-webkit-input-placeholder {
  color: #3e5869;
}

.send-message-form input:-ms-input-placeholder {
  color: #3e5869;
}

.send-message-form input::placeholder {
  color: #3e5869;
}

.help-text {
  position: absolute;
  top: 10px;
}

.heading {
  color: #478acb;
  font-size: 23px;
  padding-left: 6px;
}

.go-back {
  background: #f1f7ff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 8px #178bc616;
  border: 2px solid #0b61a4;
  border-radius: 12px;
  opacity: 1;
  color: #178bc616;
}

* {
  box-sizing: border-box;
  font-family: Montserrat;
}

.rootHeading {
  text-align: center;
}

td {
  word-wrap: break-word;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  min-height: 100%;
}

.body-content {
  background-color: #e1ebf3;
  padding-top: 3%;
}

.body-content-inner {
  display: block;
  margin: 0 auto;
}

#app-container,
#app-row-content {
  min-height: 100vh;
}

#login {
  margin: auto;
  background-color: white;
  border-radius: 16px;
  display: block;
  padding: 50px 70px;
  max-width: 490px;
  padding-bottom: 60px;
  box-shadow: 8px 8px 8px #178bc619;
  max-height: 450px;
}

.remember-wrapper {
  display: inline-block;
}

.remember-wrapper label {
  padding-left: 10px;
  font-family: Montserrat;
  color: #478acb;
  font-size: 13px;
}

.fp-link {
  float: right;
  color: #00094e;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13px;
}

#login-container {
  margin-top: 140px;
}

#weatherTable td {
  padding: 0px;
}

#weatherTable {
  margin-top: 3%;
}

#keypad {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px;
}

#keypadReset {
  margin-top: 7%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#keypad-container {
  margin-top: 2%;
}

.result {
  font-size: 20px;
  border: 1px solid #89bdf8;
}

#weather-wrapper {
  margin-left: 1%;
}

#acres {
  margin-top: 6%;
  margin-left: 20%;
}

#regionDropdown {
  max-height: 250px;
  overflow: auto;
}

#errorModal {
  margin-top: 20%;
}

#errorModal #errorModalTitle {
  color: #db3939;
  text-transform: lowercase;
  display: inline-block;
}

#errorModal #errorModalTitle::first-line {
  text-transform: capitalize;
}

#errorModal #errorModalBody {
  text-align: center;
}

#errorModal #errorModalFooter {
  margin: auto;
}

.col-half-offset {
  margin-left: 3.7%;
}

.clearCSS {
  background: transparent;
  border-bottom-left-radius: 15px;
  color: #065097;
}

.login-title {
  font: 700 40px/63px Montserrat !important;
  letter-spacing: 0px;
  color: #12426f;
}

label.form-field-label {
  color: #12426f;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 1.42px;
}

input.form-control.form-control-lg.form-input-field {
  background: #e5f2fe 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: none;
}

hea
  input.form-control.form-control-lg.form-input-field::-webkit-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

input.form-control.form-control-lg.form-input-field:-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

input.form-control.form-control-lg.form-input-field::-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

input.form-control.form-control-lg.form-input-field:-ms-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

input.login-button {
  background: #0b61a4 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 8px #178bc616;
  border-radius: 12px;
  max-height: 45px;
  font-size: 1em;
  font-family: Montserrat;
  text-transform: capitalize;
}

.login-logo-container {
  background-color: #0b4c80;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  text-align: center;
  min-height: 242px;
  justify-content: center;
}

.copyright-wrapper {
  text-align: center;
  padding-top: 15px;
  color: #065097;
  font-family: Montserrat;
  font-weight: Regular;
  font-size: 14px;
}

img.login-logo {
  max-width: 370px;
  align-content: initial;
  height: auto;
  max-height: 84px;
  text-align: center;
  margin-top: 4%;
}

.title-svg {
  margin-top: 10px;
}

.rdt_TableHeader {
  display: none !important;
}

.rdt_TableBody {
  background-color: #e1ebf3;
}

.rdt_TableCol {
  color: #12426f !important;
  font-size: 1.1em !important;
  font-family: Montserrat !important;
  background-color: #e1ebf3 !important;
}

.rdt_TableHeadRow {
  border-bottom-style: none !important;
}

.jEDPQU {
  display: block !important;
}

.rdt_Pagination {
  background-color: #e1ebf3 !important;
  border-top: none !important;
  color: #2c304c !important;
}

.table-wrapper {
  width: 100%;
}

.caret {
  display: none;
}

.rdt_TableRow {
  font-size: 13px !important;
  color: #2c304c !important;
  font-family: Montserrat !important;
  background-color: white !important;
  border-bottom-color: #edf2f2 !important;
  border-radius: 10px;
  height: 65px;
  margin-bottom: 8px;
}

.rdt_TableRow:nth-child(even) {
  background-color: #eff6ff !important;
}

button#dropdown-action-user-org {
  background-color: transparent !important;
  border-color: transparent;
  text-align: left;
  box-shadow: none;
  width: 100%;
  max-width: 34px;
  font-size: 17px;
  text-align: center;
  margin-top: 10px;
  padding: 0;
}

button#dropdown-action-user-org img {
  min-width: 16px;
}

button#dropdown-action-user-org:after {
  display: none;
}

button#dropdown-action-user-org .dropdown-menu {
  top: -23px !important;
}

.table-action-button {
  width: 30px;
  cursor: pointer;
}

.table-number {
  color: #478acb;
  font-size: 20px;
  font-family: Montserrat !important;
  font-weight: 500;
}

.table-sub-header {
  color: #478acb;
  font-size: 16px;
  font-family: Montserrat !important;
  font-weight: 500;
}

.table-delete {
  color: #b03541;
  text-decoration: underline;
  font-size: 13px;
}

.table-delete img {
  padding-right: 5px;
  max-width: 15px;
}

.edit-table-wrapper.dropdown-menu.show {
  width: 10px !important;
  float: right !important;
  min-width: -webkit-max-content;
  min-width: max-content;
  margin-right: 10px;
}

.table-edit {
  color: #168ec9;
  text-decoration: underline;
  font-size: 13px;
}

.table-edit img {
  padding-right: 5px;
  max-width: 15px;
}

.table-dispatch {
  color: #3ac916;
  text-decoration: underline;
  font-size: 13px;
}

.table-dispatch img {
  padding-right: 5px;
  max-width: 15px;
}

.main-heading {
  font-size: 1.7em;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: #065097;
  font-weight: bolder;
}

.form-button-wrapper {
  right: 0;
  bottom: -10px;
}

button#dropdown-action {
  background-color: transparent !important;
  text-align: left;
}

.select-dropdown {
  box-shadow: 8px 8px 8px #178bc616;
  border-radius: 12px !important;
  border: none !important;
  height: 46px !important;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
}

hea .select-dropdown::-webkit-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.select-dropdown:-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.select-dropdown::-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.select-dropdown:-ms-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.select-dropdown:after {
  content: '▼';
  padding: 12px 8px;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
}

.custom-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #178bc616;
  height: 40px !important;
  border-radius: 12px !important;
  border: none !important;
}

hea .custom-input::-webkit-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.custom-input:-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.custom-input::-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.custom-input:-ms-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 14px;
}

.custom-form label {
  text-align: left;
  font: 600 16px/35px Montserrat;
  letter-spacing: 1.18px;
  color: #12426f;
  opacity: 1;
}

.required-text {
  text-align: right;
  font: 300 15px/23px Montserrat;
  letter-spacing: 1.24px;
  color: #21509e;
}

.solid-button {
  background: #0b61a4 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 8px #178bc616 !important;
  font-size: 15px !important;
  color: white !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  height: 45px;
  max-width: 131px;
  width: 100px;
  padding: unset !important;
  height: 40px;
}

.outline-button {
  box-shadow: 5px 5px 8px #178bc616 !important;
  border: 2px solid #316ea9 !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  color: #0b61a4 !important;
  width: 100px;
  padding: unset !important;
  height: 40px;
  background-color: transparent !important;
}

canvas.chartjs-render-monitor {
  position: absolute;
  top: -80px;
}

.pie-wrapper {
  position: relative;
  display: block;
}

.pie-wrapper-dash {
  height: 199px;
}

.table-search-wrapper {
  position: relative;
}

input.table-search {
  position: absolute;
  right: 0px;
  z-index: 10;
  bottom: 7px;
  height: 40px;
  width: 220px;
  border-radius: 12px;
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #178bc616;
}

hea input.table-search::-webkit-input-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 10px;
}

input.table-search:-moz-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 10px;
}

input.table-search::-moz-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 10px;
}

input.table-search:-ms-input-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 10px;
}
/*# sourceMappingURL=main.css.map */

/* CSS rules to help patch things together.
   Ideally, this is an empty file.
*/

.force-block-display {
    display: block !important;
  }

.red-border-line {
    border: 1px #dc3545 solid;
    border-radius: 5px;
}
